import Cookies from "js-cookie";
import moment from "moment";
import { createTimeZoneList } from "src/submodules/ui-components/tz";

export function saveObjToLocal(title, value) {
	localStorage.setItem('_' + title, JSON.stringify(value));
}

export function removeSingleItemFromLocal(title) {
	localStorage.removeItem('_' + title);
}

export function getObjFromLocal(objTitle) {
	let obj = null;

	try {
		const storedData = localStorage.getItem('_' + objTitle);

		if (storedData) {
			obj = JSON.parse(storedData);
		}
	} catch (err) {
		// If stored data is not a strigified JSON this might fail,
		// that's why we catch the error
	}

	return obj;
}

export function cleanLocalStorage() {
	for (var k in localStorage) {
		if (k.startsWith('_')) {
			localStorage.removeItem(k);
		}
	}
}

export function isEmptyObject(obj) {
	if (obj === undefined || obj === null) return true;
	else return Boolean(Object.keys(obj).length === 0);
}

export function isEmptyList(obj) {
	if (obj === undefined || obj === null) return true;
	else return Boolean(obj.length === 0);
}

export function isEmptyString(string) {
	return Boolean(string === undefined || string === null || string === '');
}


export function getValueAssetState(list,key){
    var obj = list?.filter(as=> as?.key === key);
    if(obj && obj?.length > 0){
      return obj[0]?.value;
    }
  }

export function getDomainName()
{
	let hostName = window.location.hostname;
    return hostName.substring(hostName.lastIndexOf(".", hostName.lastIndexOf(".") - 1));
}

export function diffTime(end,start){

	let endMoment = moment(end);
	let startMoment = moment(start);
	const diff_s = endMoment.diff(startMoment,'milliseconds');
	return moment.utc(moment.duration(diff_s,'milliseconds').asMilliseconds()).format("DD HH:mm:ss")
}


export function getHoursFromSeconds(secs){
	if(secs){
		return moment.utc(secs*1000).format('HH:mm:ss');
	}else{
		return "-";
	}
}

export function changeMomentLang(settings){
	let language = settings?.language;
	var current = language.split('-')[0];
	moment.locale(current);
  }

export function saveOffset(timezone){
	const value = timezone?.value;
	Cookies.set('offset', value,{domain:getDomainName()});
}

export function saveTimeZoneList(list){
	const value = JSON.stringify(list);
	sessionStorage.setItem('timeZoneList', value);
	localStorage.setItem('timeZoneList', value);
	Cookies.set('timeZoneList', value,{domain:getDomainName()});
}

export function getOffset(){
	let offset = Cookies.get("offset");
	if(!offset){
		const list = createTimeZoneList();
		var timezoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;
		var timesSplit = timezoneString.split("/");
		var timezoneByBrowser = list.filter(t=> t.label.includes(timesSplit[1]));
		offset = timezoneByBrowser[0]?.value;
	}
	Cookies.set('offset', offset,{domain:getDomainName()});
	return parseInt(offset);
}

export function getTimeZoneList(){
	let timeZoneList = localStorage.getItem("timeZoneList");
	if(!timeZoneList){
		timeZoneList = createTimeZoneList();
		return timeZoneList;
	}else{
		return JSON.parse(timeZoneList);
	}
}


  export function calculateOffset(timeZone){
	const ct = require('countries-and-timezones');
	const timezone = ct.getTimezone(timeZone);
    var result = timezone.utcOffset/60;
	if(isCestOrCet(timezone?.name)){
		result++;
	}
    return result;
    };

function isCestOrCet(timezoneName) {
		const timeZoneName = Intl.DateTimeFormat(moment.locale, {
		  timeZoneName: "short",
		  timeZone: timezoneName,
		})
		  .formatToParts()
		  .find((i) => i.type === "timeZoneName").value;

		if(!timeZoneName.includes("GMT") && timeZoneName.includes("CEST")){
			return true;
		}
		return false;
	  }