import mock from 'src/utils/mock';
import {URLS} from 'src/submodules/commons-system/constants/urls'; 

let obj = [
    {
        id:"admin", 
        name:"admin-tenants", 
        description:"Descrizione di test"
    },

]

localStorage.setItem("obj", JSON.stringify(obj))
let obj1 = localStorage.getItem('obj');
console.log("obj1",obj1);
let obj2 = JSON.parse(obj1);
mock.onGet(URLS.SECURITY.DESCRIBE).reply(200, {
    results:[
        
            ...obj2
        
    ]
})
mock.onGet(`${URLS.SECURITY.DESCRIBE}/admin`).reply(200, {
    result: {
        id:"admin", 
        name: "admin-tenants",
        description: "Descrizione di test"
    }
})

mock.onPost(`${URLS.SECURITY.DESCRIBE}`).reply((request) => {
    const  update  = JSON.parse(request.data);
    console.log(update.params.name)
    let newObj = {
        id: update.params.name, 
        name: update.params.name, 
        description: update.params.description
    }
    obj.push(newObj); 
    let result={
        id: request.data.name,  
        name: request.data.name,
        description: request.data.description
    }
    return [200, {data: result}];
});

mock.onPut(`${URLS.SECURITY.DESCRIBE}/admin`).reply((request) => {
    const { update } = JSON.parse(request.data);
    let result={
        id: "admin",  
        name: "admin-tenants",
        description: "Descrizione di test"
    }
	return [200, {result: result}];
});