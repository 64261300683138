import mock from 'src/utils/mock';
import { URLS } from 'src/submodules/commons-system/constants/urls';

const db = {
  firstName: 'Mario',
  lastName: 'Rossi',
  name: 'Mario Rossi',
  fiscalCode: 'RSSMRA23B67D123X',
  vatCode: '01234556789',
  phone: '123456789',
  mobilePhone: '123456789',
  fax: '123456789',
  emailCrm: '???????????',
  email: 'mariorossi@email.it',
  emailCc: 'mariorossicc@email.it',
  pec: 'mariorossi@pec.it',
  pecCc: 'mariorossicc@pec.it',
  codSdi: 'T9K4ZHO',
  pecSdi: 'sdi@pec.it',
  legalAddress: {
    name: 'Via ... - Roma'
  }
}

// USERS
mock.onGet('/api/v1/settings/users/1').reply(200, {
  result: {
    id: 1,
    fullName: 'Nome Utente',
    firstName: 'Nome',
    lastName: 'Cognome',
    username: 'Username',
    role: 'Role',
    email: 'utente@email.it',
    phone: '+39 123 4567890',
    isActive: true,
    businessPartner: {
      id: 2,
      fullName: 'Business Partner',
      email: 'bPartner@email.it',
      phone: '+39 123 4567890',
      image: 'http://localhost:3000/static/images/avatars/avatar_6.png',
    },
    options: {
      ticket: true,
      option1: false,
      option2: true,
    }
  }
})

mock.onGet(URLS.PROFILES.PROFILES).reply(200, {
  result: db
})

mock.onPut(URLS.PROFILES.PROFILES).reply(200)