class G7Service {


	isG7(){
		const isG7 = localStorage.getItem('G7');
		return Boolean(isG7);
	}

	saveG7Param(){
		localStorage.setItem('G7', true);
	}

    removeG7Param(){
		localStorage.removeItem('G7');
	}

}

const g7Service = new G7Service();

export default g7Service;