import 'src/mock'; // TODO decommentare per abilitare mock
import { enableES5 } from 'immer';
import 'moment/locale/it';
import 'nprogress/nprogress.css';
import React, { Suspense } from "react";
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { I18nextProvider } from 'react-i18next';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-quill/dist/quill.snow.css';
import { Provider } from 'react-redux';
import 'src/assets/css/prism.css';
import { SettingsProvider } from 'src/context/SettingsContext';
import 'src/mixins/chartjs';
import 'src/mixins/prismjs';
import * as serviceWorker from 'src/serviceWorker';
import { configureStore } from 'src/store';
import { restoreSettings } from 'src/utils/settings';
import i18n from './i18n';
import LoadingScreen from './components/LoadingScreen';

enableES5();

const store = configureStore();
const settings = restoreSettings();
const App = React.lazy(() => import("./App"));
ReactDOM.render(
	<I18nextProvider i18n={i18n}>
		<Provider store={store}>
			<SettingsProvider settings={settings}>
				<Suspense fallback={<LoadingScreen/>}>
					<App />
				</Suspense>
			</SettingsProvider>
		</Provider>
	</I18nextProvider>,
	document.getElementById('root')
);

serviceWorker.unregister();
