import mock from 'src/utils/mock';
import { URLS } from 'src/submodules/commons-system/constants/urls';

mock.onGet(URLS.CONFIG.PRIVATE).reply(200, {
	appName: process.env.REACT_APP_BE_APP_NAME,
	appIcon: process.env.REACT_APP_BE_APP_ICON,
	appGoogleMapsApiKey: process.env.REACT_APP_BE_GMAPS_KEY,
	appContractMultiline: process.env.REACT_APP_BE_CONTRACT_MULTILINE,
	appEcobonusGdpr: process.env.REACT_APP_BE_ECOBONUS_GDPR,
	appContractAgencyHeader: process.env.REACT_APP_BE_CONTRACT_AGENCY_MODE,
	usernameGeneration: process.env.BE_USERNAME_GENERATION,
	appRelationsCustomerTypeTypologyUse: process.env.REACT_APP_BE_RELATIONS_CUSTOMER_TYPE_TYPOLOGY_USE
});