import _ from 'lodash';
import jwt from 'jsonwebtoken';
import mock from 'src/utils/mock';
import { URLS } from 'src/submodules/commons-system/constants/urls';

const JWT_SECRET = 'devias-top-secret-key';
const JWT_EXPIRES_IN = '2 days';

const db = {
	user: {
		id: 'admin',
		email: 'renee.mckelvey@gruppoavvocati.it',
		name: 'Renee McKelvey',
		firstName: 'Renee',
		lastName: 'McKelvey',
		avatar: '/static/images/avatars/avatar_4.png',
		phone: '3486901374',
		timezone: null,
		role: 'Manager',
		country: null,
		isPublic: null,
		authorities: [
			{
				id: 'ROLE_USER'
			},
			{
				id: 'ROLE_ADMINISTRATOR'
			},
			{
				id: 'ROLE_TICKET'
			}
		],
		functions: [
			{
				id: 'commons.*'
			},
			{
				id: 'commons.usermanagement.*'
			},
			{
				id: 'telemago.*'
			}
		],
		groups: [
			{
				id: 'APE Supervisor',
				name: 'APE Supervisor',
				functions: [
					{
						id: 'eportal.inspection.*'
					},
					{
						id: 'eportal.customer.read'
					},
					{
						id: 'eportal.agency.read'
					}
				],
				groups: [
					{
						id: 'APE Agent',
						name: 'APE Agent',
						functions: [
							{
								id: 'eportal.inspection.create'
							},
							{
								id: 'eportal.customer.read'
							},
							{
								id: 'eportal.inspection.read'
							},
							{
								id: 'eportal.agency.read'
							},
							{
								id: 'eportal.inspection.menu'
							},
							{
								id: 'eportal.agency.menu'
							},
							{
								id: 'eportal.agency.customer.menu'
							},
							{
								id: 'commons.task.*'
							},
							{
								id: 'commons.workflow.*'
							},
							{
								id: 'eportal.inspection.update'
							}
						],
						mergedFunctions: [
							'eportal.inspection.create',
							'eportal.customer.read',
							'eportal.inspection.read',
							'eportal.agency.read',
							'eportal.inspection.menu',
							'eportal.agency.menu',
							'eportal.agency.customer.menu',
							'commons.task.*',
							'commons.workflow.*',
							'eportal.inspection.update'
						]
					}
				],
				mergedFunctions: [
					'telemago.portal.inspection.*',
					'telemago.portal.customer.read',
					'telemago.portal.agency.read',
					'telemago.portal.inspection.create',
					'telemago.portal.customer.read',
					'telemago.portal.inspection.read',
					'telemago.portal.agency.read',
					'telemago.portal.inspection.menu',
					'telemago.portal.agency.menu',
					'telemago.portal.agency.customer.menu',
					'commons.task.*',
					'commons.workflow.*',
					'telemago.portal.inspection.update'
				]
			}
		],
		tenants: [
			{
				id: 1,
				name: 'OHM',
				code: 'test',
				description: 'Descrizione testo',
				enabled: true,
				serviceUser: 'admin'
			}
		],
		tenantsProperties: [
			{
				id: 218,
				tenantId: 1,
				key: 'telemago.portal.profile.bpartner',
				value: '458'
			},
			{
				id: 218,
				tenantId: 1,
				key: 'telemago.portal.profile.agent',
				value: '458'
			}
		],
		allFunctions: [
			{
				id: 'commons.*'
			},
			{
				id: 'commons.usermanagement.*'
			},
			{
				id: 'telemago.portal.*'
			},
			{
				id: 'telemago.portal.inspection.*'
			},
			{
				id: 'telemago.portal.customer.read'
			},
			{
				id: 'telemago.portal.agency.read'
			},
			{
				id: 'telemago.portal.inspection.create'
			},
			{
				id: 'telemago.portal.inspection.read'
			},
			{
				id: 'telemago.portal.inspection.menu'
			},
			{
				id: 'telemago.portal.agency.menu'
			},
			{
				id: 'telemago.portal.agency.customer.menu'
			},
			{
				id: 'commons.task.*'
			},
			{
				id: 'commons.workflow.*'
			},
			{
				id: 'telemago.portal.inspection.update'
			}
		]
	}
};

mock.onPost(URLS.PROFILES.LOGIN).reply((config) => {
	const { username, password } = JSON.parse(config.data);

	if (username !== 'fabio.spica@grupposistematica.it' || password !== 'admin') {
		return [400, { message: 'Per favore verifica la tua email e password' }];
	}

	const { user } = db;

	const accessToken = jwt.sign(
		{ id: user.id },
		JWT_SECRET,
		{ expiresIn: JWT_EXPIRES_IN }
	);

	return [200, { user, accessToken }];
});

mock.onGet(URLS.PROFILES.ME).reply((config) => {
	const { Authorization } = config.headers;

	if (!Authorization) {
		return [401, { message: 'Authorization token mancante' }];
	}

	try {
		const accessToken = Authorization.split(' ')[1];

		const { id } = jwt.verify(accessToken, JWT_SECRET);

		if (id !== db.user.id) {
			return [401, { message: 'Invalid authorization token' }];
		}

		return [200, { result: db.user }];
	} catch (error) {
		return [401, { message: 'Invalid authorization token' }];
	}
});

mock.onPost('/api/v1/profile/profile').reply((request) => {
	const { update } = JSON.parse(request.data);

	_.assign(db.user, update);

	return [200, { user: db.user }];
});

mock.onPost(URLS.PROFILES.LOST_PASSWORD).reply(200)

mock.onPost(URLS.PROFILES.RESET_PASSWORD).reply(200)

mock.onGet('/api/v1/profile/settings').reply(200, {
	settings: {}
});

mock.onGet('/api/v1/profile/subscription').reply(200, {
	subscription: {
		name: 'Freelancer',
		price: '5',
		currency: '$',
		proposalsLeft: 12,
		templatesLeft: 5,
		invitesLeft: 24,
		adsLeft: 10,
		hasAnalytics: true,
		hasEmailAlerts: true
	}
});
